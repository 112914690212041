import * as React from 'react';
import { IStateBoxProps } from '../StateBox.types';
import SlideShowContainer from '../../SlideShowContainer/viewer/SlideShowContainer';
import { ISlideShowContainerImperativeActions } from '../../SlideShowContainer/SlideShowContainer.types';

const StateBox: React.ForwardRefRenderFunction<
  ISlideShowContainerImperativeActions,
  IStateBoxProps
> = (props, ref) => {
  const propsWithDefaults = {
    dynamicSlidesHeight: true,
    shouldChangeSlidesOnSwipe: false,
    autoPlayInterval: 1000,
    pauseAutoPlayOnMouseOver: false,
    transitionReverse: false,
    showNavigationDots: false,
    showNavigationButton: false,
    slidesProps: [],
    autoPlay: false,
    ...props,
    ref,
  };
  return <SlideShowContainer {...propsWithDefaults} />;
};

export default React.forwardRef(StateBox);
