import StateBoxComponent from '@wix/thunderbolt-elements/src/components/StateBox/viewer/StateBox';
import StateBoxController from '@wix/thunderbolt-elements/src/components/StateBox/viewer/StateBox.controller';


const StateBox = {
  component: StateBoxComponent,
  controller: StateBoxController
};


export const components = {
  ['StateBox']: StateBox
};

