import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IStateBoxControllerProps,
  IStateBoxMapperProps,
  IStateBoxProps,
} from '../StateBox.types';

export default withCompController<
  IStateBoxMapperProps,
  IStateBoxControllerProps,
  IStateBoxProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    changeSlide: slideIndex => {
      controllerUtils.updateProps({
        currentSlideIndex: slideIndex,
      });
    },
  };
});
